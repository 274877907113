.App {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

.introduction {
  text-align: center;
}

.introduction h1 {
  margin: 0;
  padding: 0;
  font-size: 3rem;
}

.small {
  font-size: 0.75rem;
}
